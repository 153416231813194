import React from 'react';
import AllRoute from "./main-component/router";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './sass/style.scss';
import {Analytics} from "@vercel/analytics/react";
import {SpeedInsights} from "@vercel/speed-insights/react";


const App = () => {

  return (
      <div className="App" id='scrool'>
          <SpeedInsights/>
        <Analytics/>
        <AllRoute/>
        <ToastContainer/>
      </div>
  );
}

export default App;
