import axios from "axios";

class Guest {
    constructor(name, mail, song, attendance, accommodation, meals = []) {
        this.name = name;
        this.email = mail
        this.song = song;
        this.attendance = attendance;
        this.accommodation = accommodation;
        this.meals = meals;
    }
}

const url = "https://wedding-be-tan.vercel.app/pages/api/formSubmit.ts"

export const submitForm = async (parentState) => {
    const requestData = transformGuests(parentState);
    try {
        const response = await axios.post(url, requestData, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (response.status === 200) {
            alert('RSVP sent successfully!');
        }
    } catch (error) {
        throw error;
    }
}

function transformGuests(parentState) {
    const { email, attendance, accommodation, guests } = parentState;
    return guests.map(guest => new Guest(
        guest.name,
        email,
        guest.song,
        attendance === "yes",
        accommodation === "yes",
        guest.meals.map(meal => {
            return meal === "Egyéb" ? null : meal;
        }).concat(guest.otherMeal ? guest.otherMeal : null)
            .filter(meal => meal !== null)
    ));
}
