import React from 'react'
import sImg1 from '../../images/story/01.jpg'
import sImg2 from '../../images/story/02.jpg'
import sImg3 from '../../images/story/03.jpg'
import sImg4 from '../../images/story/04.jpeg'
import sImg5 from '../../images/story/05.png'

import shape1 from '../../images/story/s-shape1.png'
import shape2 from '../../images/story/s-shape2.png'
import SectionTitle from '../SectionTitle'


const StorySection4 = (props) => {
    return (

        <section className="wpo-story-section-s4 section-padding" id="story">
            <div className="container">
                <SectionTitle MainTitle={'Menetrend'} />
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="story-timeline">
                            <div className="round-shape"></div>
                            <div className="row">
                                <div className="col col-lg-6 col-12">
                                    <div className="img-holder right-align-text left-site">
                                        <img src={sImg1} alt="" className="img img-responsive"/>
                                        <div className="story-shape-img-1">
                                            <img src={shape1} alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-lg-6 col-12 text-holder">
                                    <span className="heart">
                                        <i className="fi flaticon-heart"></i>
                                    </span>
                                    <div className="story-text">
                                        <span className="date">14:00</span>
                                        <h3>Vendégvárás</h3>
                                        <p>Várjuk, hogy az utolsó részegednivágyó paraszt is idetolja a pofáját!</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col col-lg-6 col-12 order-lg-1 order-2 text-holder right-heart">
                                    <span className="heart">
                                        <i className="fi flaticon-heart"></i>
                                    </span>
                                    <div className="story-text right-align-text">
                                        <span className="date">15:00</span>
                                        <h3>Út a templomba</h3>
                                        <p>Kocsiba huppanunk és átmegyünk a szomszéd faluba, ahol a templomi szertartás
                                            lesz.
                                            Próbáljunk a lehető legkevesebb autóval mozog, mert habár a hely a
                                            parkolásra kevés a boxos annál több...
                                            Kevesebb feltünés kevesebb gond!</p>
                                    </div>
                                </div>
                                <div className="col col-lg-6 col-12 order-lg-2 order-1">
                                    <div className="img-holder right-align-img">
                                        <img src={sImg2} alt="" className="img img-responsive"/>
                                        <div className="story-shape-img-1">
                                            <img src={shape2} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col col-lg-6 col-12">
                                    <div className="img-holder video-holder left-site">
                                        <img src={sImg3} alt="" className="img img-responsive"/>
                                        <div className="story-shape-img-1">
                                            <img src={shape1} alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-lg-6 col-12 text-holder">
                                    <span className="heart">
                                        <i className="fi flaticon-heart"></i>
                                    </span>
                                    <div className="story-text">
                                        <span className="date">15:15</span>
                                        <h3>Templomi szertartás</h3>
                                        <p>Református templomi szertartás! Reméljük senkinek nincs térdproblémája,
                                            felállni leülni többször kell majd.
                                            Nótázás csak az ottani dalosból lehetséges!!!</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col col-lg-6 col-12 order-lg-1 order-2 text-holder right-heart">
                                    <span className="heart">
                                        <i className="fi flaticon-heart"></i>
                                    </span>
                                    <div className="story-text right-align-text">
                                        <span className="date">15:45</span>
                                        <h3>Lets go vissza ahonnan jöttünk</h3>
                                        <p>Ismét, kocsiba be ablakot le könyököt ki kettesbe be! Davaj vissza ahonnan gyöttünk.</p>
                                    </div>
                                </div>
                                <div className="col col-lg-6 col-12 order-lg-2 order-1">
                                    <div className="img-holder right-align-img">
                                        <img src={sImg4} alt="" className="img img-responsive"/>
                                        <div className="story-shape-img-1">
                                            <img src={shape2} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col col-lg-6 col-12">
                                    <div className="img-holder video-holder left-site">
                                        <img src={sImg5} alt="" className="img img-responsive"/>
                                        <div className="story-shape-img-1">
                                            <img src={shape1} alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-lg-6 col-12 text-holder">
                                    <span className="heart">
                                        <i className="fi flaticon-heart"></i>
                                    </span>
                                    <div className="story-text">
                                        <span className="date">16:00</span>
                                        <h3>Polgári szertartás</h3>
                                        <p>Hátsókertben reméljük rendkívül érzelmes és személyes keretek közt aláírjuk amit kell, hogy megpecsételjük sorsunkat.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col offset-lg-6 col-lg-6 col-12 text-holder">
                                    <div className="round-shape-2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default StorySection4;