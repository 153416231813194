import React, {useEffect, useState} from 'react'
import SimpleReactValidator from 'simple-react-validator';
import GuestForm from "./GuestForm";
import {submitForm} from "./SubmitForm";

const RSVPFrom = () => {

    const [parentState, setParentState] = useState({
        email: '',
        guests: [],
        attendance: 'yes',
        accommodation: 'yes'
    });

    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage',
        messages: {
            required: 'Ezt a mezőt kötelező kitölteni',
            email: 'Létező e-mail cím kell hogy legyen'
        }
    }));

    const changeHandler = e => {
        setParentState({ ...parentState, [e.target.name]: e.target.value })
        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };

    const submitHandler = e => {
        e.preventDefault();
        if (validator.allValid()) {
            validator.hideMessages();
            submitForm(parentState).then(response => {
                    console.log('Form submitted successfully:', response);
                }
            ).catch(error => {
                console.log('Error submitting form:', error);
            })
            setParentState({
                email: '',
                guests: [],
                attendance: 'yes',
                accommodation: 'yes'
            })
        } else {
            validator.showMessages();
        }
    };

    const [labels, setLabels] = useState({
        attendance: 'Igen, ott leszünk!',
        nonAttendance: 'Bocsi, nem tudunk részt venni',
        accommodation: 'Szeretnénk szállást!',
        nonAccommodation: 'Nem szeretnénk szállást'
    });

    useEffect(() => {
        if (parentState.guests.length <= 1) {
            setLabels({
                attendance: 'Igen, ott leszek!',
                nonAttendance: 'Bocsi, nem tudok részt venni',
                accommodation: 'Szeretnék szállást!',
                nonAccommodation: 'Nem szeretnék szállást'
            });
        } else {
            setLabels({
                attendance: 'Igen, ott leszünk!',
                nonAttendance: 'Bocsi, nem tudunk részt venni',
                accommodation: 'Szeretnénk szállást!',
                nonAccommodation: 'Nem szeretnénk szállást'
            });
        }
    }, [parentState.guests]);

    return (
        <form onSubmit={(e) => submitHandler(e)} className="contact-validation-active" >
            <div className="row">
                <GuestForm parentState={parentState} setParentState={setParentState}/>
                <div className="col col-lg-12 col-12">
                    <div className="radio-buttons">
                        <p>
                            <input type="radio" id="attend" name="attendance" value="yes" checked={parentState.attendance === 'yes'} onChange={changeHandler} />
                            <label htmlFor="attend">{labels.attendance}</label>
                        </p>
                        <p>
                            <input type="radio" id="not" name="attendance" value="no" checked={parentState.attendance === 'no'} onChange={changeHandler} />
                            <label htmlFor="not">{labels.nonAttendance}</label>
                        </p>
                    </div>
                </div>
                <div className="col col-lg-12 col-12">
                    <div className="radio-buttons">
                        <p>
                            <input type="radio" id="accommodation" name="accommodation" value="yes" checked={parentState.accommodation === 'yes'} onChange={changeHandler} />
                            <label htmlFor="accommodation">{labels.accommodation}</label>
                        </p>
                        <p>
                            <input type="radio" id="nonAccommodation" name="accommodation" value="no" checked={parentState.accommodation === 'no'} onChange={changeHandler} />
                            <label htmlFor="nonAccommodation">{labels.nonAccommodation}</label>
                        </p>
                    </div>
                </div>
                <div className="col col-lg-12 col-12">
                    <div className="form-field">
                        <input
                            value={parentState.email}
                            type="email"
                            name="email"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            className="form-control"
                            placeholder="Email amin értesíthetünk titeket"/>
                        {validator.message('email', parentState.email, 'required|email')}
                    </div>
                </div>
            </div>
            <div className="submit-area">
                <button type="submit" className="theme-btn">Küldés</button>
            </div>
        </form>
    )
}

export default RSVPFrom;