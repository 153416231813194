import React, { useState, useEffect } from 'react';
import SimpleReactValidator from 'simple-react-validator';

const MealPreferencesForm = ({ guest = { meals: [], otherMeal: '' }, setGuest }) => {
    const [showOtherField, setShowOtherField] = useState(guest.meals.includes('Egyéb'));
    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage',
    }));

    useEffect(() => {
        setShowOtherField(guest.meals.includes('Egyéb'));
    }, [guest.meals]);

    const changeHandler = (e) => {
        const { name, value, checked } = e.target;
        let updatedMeal = [...guest.meals];

        if (name === 'meal') {
            if (checked) {
                updatedMeal.push(value);
            } else {
                updatedMeal = updatedMeal.filter((meal) => meal !== value);
            }
            setGuest({ ...guest, meals: updatedMeal });
            setShowOtherField(value === 'Egyéb' && checked);
        } else if (name === 'otherMeal') {
            const otherMealPref = value.replace('Egyéb', '').trim()
            updatedMeal.push(otherMealPref);
            setGuest({ ...guest, meals: updatedMeal });
            setGuest({ ...guest, otherMeal: value });
        }

        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };
    return (
        <div className="col col-lg-12 col-12">
            <div className="form-field">
                <label>Van egyedi étkezési igényed?</label>
                <div className="checkbox-group">
                    <div className="checkbox-item">
                        <input
                            type="checkbox"
                            id="laktoze"
                            name="meal"
                            value="Laktózmentes"
                            checked={guest.meals.includes('Laktózmentes')}
                            onChange={changeHandler}
                        />
                        <label htmlFor="laktoze">Laktózmentes</label>
                    </div>
                    <div className="checkbox-item">
                        <input
                            type="checkbox"
                            id="gluten"
                            name="meal"
                            value="Gluténmentes"
                            checked={guest.meals.includes('Gluténmentes')}
                            onChange={changeHandler}
                        />
                        <label htmlFor="gluten">Gluténmentes</label>
                    </div>
                    <div className="checkbox-item">
                        <input
                            type="checkbox"
                            id="milk"
                            name="meal"
                            value="Tejmentes"
                            checked={guest.meals.includes('Tejmentes')}
                            onChange={changeHandler}
                        />
                        <label htmlFor="milk">Tejmentes</label>
                    </div>
                    <div className="checkbox-item">
                        <input
                            type="checkbox"
                            id="meat"
                            name="meal"
                            value="Húsmentes"
                            checked={guest.meals.includes('Húsmentes')}
                            onChange={changeHandler}
                        />
                        <label htmlFor="meat">Húsmentes</label>
                    </div>
                    <div className="checkbox-item">
                        <input
                            type="checkbox"
                            id="other"
                            name="meal"
                            value="Egyéb"
                            checked={guest.meals.includes('Egyéb')}
                            onChange={changeHandler}
                        />
                        <label htmlFor="other">Egyéb</label>
                        {showOtherField && (
                            <input
                                type="text"
                                name="otherMeal"
                                className="form-control"
                                placeholder="Egyéb"
                                value={guest.otherMeal}
                                onChange={changeHandler}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MealPreferencesForm;