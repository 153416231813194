import React, { useState } from 'react';
import MealPreferencesForm from "./MealfenreForm";

const GuestForm = ({ parentState, setParentState }) => {
    const [guest, setGuest] = useState(
        {
            name: '',
            song: '',
            meals: [],
            otherMeal: '' });

    const handleGuestChange = (e) => {
        setGuest({ ...guest, [e.target.name]: e.target.value });
    };

    const addGuest = () => {
        setParentState({
            ...parentState,
            guests: [...parentState.guests, guest]
        });
        setGuest({ name: '', song: '', meals: [], otherMeal: '' });
    };

    return (
        <div className="col col-lg-12 col-12">
            <div className="form-field">
                <input
                    value={guest.name}
                    type="text"
                    name="name"
                    onChange={handleGuestChange}
                    className="form-control"
                    placeholder="Vendég neve"
                />
            </div>
            <div className="form-field">
                <input
                    value={guest.song}
                    type="text"
                    name="song"
                    onChange={handleGuestChange}
                    className="form-control"
                    placeholder="Melyik zenére buliznál velünk szívesen?"
                />
            </div>
            <MealPreferencesForm guest={guest} setGuest={setGuest}/>
            <div className="submit-area">
                <button type="button" style={{marginBottom: "12px"}} onClick={addGuest} className="theme-btn">Vendég hozzáadás</button>
            </div>
            <ul>
                {parentState.guests.map((g, index) => (
                    <li key={index} style={{padding: "6px"}}>Vendég: {g.name} - Választott zene: {g.song} - Étkezési igények: {g.meals.join(', ')} {g.otherMeal}</li>
                ))}
            </ul>
        </div>
    );
};

export default GuestForm;
